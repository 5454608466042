// import { ReactNode } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';

// Mbele
import MbeleIndex from 'containers/Landing/Mbele/index';
import MbeleFaq from 'containers/Landing/Mbele/faq';
import MbeleFeatures from 'containers/Landing/Mbele/features';
import MbelePhysio from 'containers/Landing/Mbele/physio';
import MbeleSales from 'containers/Landing/Mbele/sales';
import MbeleFooter from 'containers/Landing/Mbele/components/Layout/Footer';
import MbeleNavbar from 'containers/Landing/Mbele/components/Layout/Navbar';

// Feedback Session
import FSIndex from 'containers/Landing/FeebackSession/index';
import FSAbout from 'containers/Landing/FeebackSession/about';
import FSContact from 'containers/Landing/FeebackSession/contact';
import FSPricing from 'containers/Landing/FeebackSession/pricing';
import FSFeatures from 'containers/Landing/FeebackSession/features';
import FSSolutions from 'containers/Landing/FeebackSession/solutions';
import FSPrivacy from 'containers/Landing/FeebackSession/privacy';
import FSTos from 'containers/Landing/FeebackSession/tos';
import FSSignIn from 'containers/Landing/FeebackSession/auth/signin';
import FSSignUp from 'containers/Landing/FeebackSession/auth/signup';
import FSPasswordReset from 'containers/Landing/FeebackSession/auth/password-reset';
import FSLayout from 'containers/Landing/FeebackSession/layout';
import FSNotFound from 'containers/Landing/FeebackSession/not-found';
import FSEventbrite from 'containers/Landing/FeebackSession/integration/eventbrite';

// Other
import PublicProfile from 'containers/User/public-profile';
import { constants } from 'utils';
import Auth from 'containers/Auth';
import { EmbedRoutes } from './common-routes';

const FSMainLayout = () => (
  <FSLayout>
    <Outlet />
  </FSLayout>
);

const MbeleLayout = () => (
  <>
    <MbeleNavbar />
    <main>
      <Outlet />
    </main>
    <MbeleFooter />
  </>
);

export default function App() {
  if (constants.isFeedbackSession) {
    return (
      <Routes>
        <Route element={<FSMainLayout />}>
          <Route path="/" element={<FSIndex />} />
          <Route path="/about" element={<FSAbout />} />
          <Route path="/contact" element={<FSContact />} />
          <Route path="/pricing" element={<FSPricing />} />
          <Route path="/features" element={<FSFeatures />} />
          <Route path="/integration/eventbrite" element={<FSEventbrite />} />
          <Route path="/solutions" element={<FSSolutions />} />
          <Route path="/privacy" element={<FSPrivacy />} />
          <Route path="/tos" element={<FSTos />} />
          <Route path="/signin" element={<FSSignIn />} />
          <Route path="/signup" element={<FSSignUp />} />
          <Route path="/password-reset" element={<FSPasswordReset />} />
          <Route path="/404" element={<FSNotFound />} />
        </Route>
        <Route path="/embed/*" element={<EmbedRoutes />} />
        <Route
          path="/:username"
          element={
            <FSLayout>
              <PublicProfile />
            </FSLayout>
          }
        />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route element={<MbeleLayout />}>
          <Route path="/" element={<MbeleIndex />} />
          <Route path="/page/faq" element={<MbeleFaq />} />
          <Route path="/page/features" element={<MbeleFeatures />} />
          <Route path="/page/physio" element={<MbelePhysio />} />
          <Route path="/page/sales" element={<MbeleSales />} />
          <Route path="/login" element={<Auth />} />
          <Route path="/register" element={<Auth />} />
          <Route path="/:username" element={<PublicProfile />} />
        </Route>
        <Route path="/embed/*" element={<EmbedRoutes />} />
      </Routes>
    );
  }
}
