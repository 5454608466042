export const FEEDBACK = {
  RECEIVED: 'RECEIVED',
  SENT: 'SENT',
};

export const ROUTE_MAP = {
  dashboard: '/',
  request: '/request',
  intent: '/intent',
  search: '/u/#/',
  session: '/call',
  resource: '/manage-file',
  addClient: '/client',
  manageRate: '/manage-rate',
  manageAvailabilty: '/availability',
  analytics: '/analytics',
  manage: {
    client: '/client',
    account: '/account',
    rate: '/manage-rate',
    availability: '/availability',
    financial: '/financial',
  },
  client: {
    dashboard: '/',
    ask: '/ask',
    request: '/request',
    account: '/account',
    resource: '/file',
  },
  business: {
    settings: '/business/settings',
    analytics: '/business/analytics',
    integrations: '/business/integrations',
    member: '/business/member',
    embed: '/business/embed',
  },
  integration: {
    eventbrite: {
      index: '/integration/eventbrite',
      event: '/integration/eventbrite/event',
    },
  },
};

export const APP = process.env.REACT_APP_APP || '';
export const BASE_DOMAIN = process.env.NODE_ENV === 'production' ? `${APP}.io` : 'localhost:3000';
export const API_URL =
  process.env.REACT_APP_API_URL ||
  (process.env.NODE_ENV === 'production' ? `https://api.${BASE_DOMAIN}` : '');
export const APP_URL =
  process.env.REACT_APP_APP_URL ||
  (process.env.NODE_ENV === 'production' ? `https://app.${BASE_DOMAIN}` : '/');
export const LANDING_PAGE_URL =
  process.env.NODE_ENV === 'production' ? `https://${BASE_DOMAIN}` : '';

export const GOOGLE_CLIENT_ID =
  '189572724406-qapmmka6je6qagc5jjo08pp18iidks5f.apps.googleusercontent.com';
export const FACEBOOK_APP_ID = '1236163467193157';
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
export const isFeedbackSession = (APP || '').toLowerCase() === 'feedbacksession';
export const APP_HUMAN_NAME = isFeedbackSession ? 'Feedback Session' : 'Mbele';
