import { ElementType } from 'react';

// Material UI
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// App
// import MethodComponent from 'containers/Auth/components/Method';
import EmailComponent from 'containers/Auth/components/Email';
import { segmentAnalytics, SEGMENT_CONSTANTS } from 'utils/analytics';
import { APP_HUMAN_NAME } from 'utils/constants';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 400,
    marginBottom: 100,
    [theme.breakpoints.down('sm')]: {
      marginTop: 40,
      width: 320,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));

interface MainProps {
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
  setIsEmail: (value: boolean) => void;
  setIsLogin: (value: boolean) => void;
  isEmail: boolean;
  isLogin: boolean;
  HeaderComponent?: ElementType;
  marginTop?: number;
  initialEmail?: string;
}

export default function Main({
  onSuccess,
  setIsEmail,
  setIsLogin,
  isLogin,
  HeaderComponent,
  marginTop,
  initialEmail,
}: MainProps): JSX.Element {
  const classes = useStyles({ marginTop });

  let headerTitle = 'Login';
  let otherContent = <div />;
  let subtitle = '';

  if (isLogin) {
    headerTitle = `Log in to ${APP_HUMAN_NAME}`;
    subtitle = 'Manage your account, check notifications, give and get feedback, and more.';
    otherContent = (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="tw-text-center tw-text-base">
          Don't have an accounts?{' '}
          <Button
            id="toggle-login-button"
            color="primary"
            size="small"
            className="tw-text-base"
            onClick={() => {
              setIsEmail(false);
              setIsLogin(!isLogin);
              segmentAnalytics.track(SEGMENT_CONSTANTS.LOGIN_CLICKED);
            }}
          >
            Sign up
          </Button>
        </div>

        <div className="tw-text-center tw-text-base">
          Forgotten Your Password?{' '}
          <Button
            id="toggle-login-button"
            color="primary"
            size="small"
            href="/accounts/password/reset/"
            className="tw-text-base"
            onClick={() => {
              segmentAnalytics.track(SEGMENT_CONSTANTS.FORGOTTEN_PASSWORD_CLICKED);
            }}
          >
            Reset Password
          </Button>
        </div>
      </div>
    );
  } else {
    headerTitle = `Sign up for ${APP_HUMAN_NAME}`;
    subtitle = 'Create a profile, upload video to give or get feedback, and more.';
    otherContent = (
      <div className="tw-text-center tw-text-base">
        Already have an account?{' '}
        <Button
          id="toggle-login-button"
          color="primary"
          size="small"
          className="tw-text-base"
          onClick={() => {
            setIsEmail(false);
            setIsLogin(!isLogin);
            segmentAnalytics.track(SEGMENT_CONSTANTS.SIGNUP_CLICKED);
          }}
        >
          Log in
        </Button>
      </div>
    );
  }

  let renderHeaderComponent = (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12}>
        <Typography id="header-title" variant="h5" gutterBottom align="center">
          {headerTitle}
        </Typography>

        <div className="tw-my-2 tw-text-center tw-text-base">{subtitle}</div>
      </Grid>
    </Grid>
  );

  if (HeaderComponent) {
    renderHeaderComponent = <HeaderComponent title={headerTitle} />;
  }
  return (
    <form id="signup-form" className={classes.form} noValidate data-test-id="signup-form">
      {renderHeaderComponent}

      <div className="tw-mt-1" />

      {/* {!isEmail ? (
        <MethodComponent
          onClose={onClose}
          onSuccess={onSuccess}
          setIsEmail={setIsEmail}
          isLogin={isLogin}
        />
      ) : ( */}
      <EmailComponent onSuccess={onSuccess} isLogin={isLogin} initialEmail={initialEmail} />
      {/* )} */}
      <Grid justifyContent="center" style={{ marginTop: 30 }}>
        <Grid xs={12} sm={12} md={12}>
          {otherContent}
        </Grid>
      </Grid>
    </form>
  );
}
