import { useState } from 'react';
import Linkify from 'react-linkify';
import classnames from 'classnames';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

// Material UI
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import grey from '@mui/material/colors/grey';
import CardMedia from '@mui/material/CardMedia';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AudioPlayer from 'react-h5-audio-player';

// App
// @ts-ignore
import pdfImage from 'images/pdf.png';
// @ts-ignore
import cameraImage from 'images/camera.png';
// @ts-ignore
import playImage from 'images/play.png';
import UsernameLink from 'components/User/UsernameLink';
import { UserAvatar } from 'components/Common/Avatar';
import { GridContainer } from 'components/Common/index';
import { CardMediaContentOverlay } from 'styles/common.style';
import { ConfirmDeleteModal } from 'components/Common/ConfirmDelete';
import { comment as commentApi, feedbackRequest as feedbackRequestApi } from 'store/index';
import { buttonPrimary, buttonDanger } from 'styles/classnames';
import { CommentType, UserBaseType, MediaTypeEnum } from 'types';
import { useDispatch, useSelector } from 'hooks';

const useStyles = makeStyles({
  center: {},
  createdAtText: {
    fontSize: 14,
    color: grey[700],
  },
  media: {
    height: 150,
    width: 150,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  linkComment: {
    '& a': { color: '#0645AD', textDecoration: 'underline' },
  },
});

function CommentAvatar({ comment }: { comment: CommentType }) {
  return <UserAvatar user={comment.created_by} size={40} />;
}

function ImageAsIcon({ src }: { src: string }) {
  return <img src={src} style={{ width: 30, height: 30 }} />;
}

interface RenderCommentType {
  comment: CommentType;
  setSelectedComment: (comment: CommentType) => void;
}

function RenderComment({ comment, setSelectedComment }: RenderCommentType) {
  const classes = useStyles();
  const request = useSelector((state: any) => state[feedbackRequestApi.APP_NAME].detail);

  if (comment.video_comment) {
    const media_type = comment.video_comment.media_type;

    let icon = <ImageAsIcon src={playImage} />;

    if (media_type === MediaTypeEnum.IMAGE) {
      icon = <ImageAsIcon src={cameraImage} />;
    } else if (media_type === MediaTypeEnum.PDF) {
      icon = <ImageAsIcon src={pdfImage} />;
    } else if (media_type === MediaTypeEnum.AUDIO) {
      return <AudioPlayer src={comment.video_comment.video} customAdditionalControls={[]} />;
    } else if (media_type === MediaTypeEnum.DOC) {
      return (
        <Link to={`/request/${request.id}/doc/comment/${comment.id}`}>
          <div className="tw-flex tw-cursor-pointer tw-flex-col">
            <CardMedia className={classes.media} title={comment.video_comment.title}>
              <Icon icon="file-icons:microsoft-word" style={{ fontSize: 80, color: '#3b82f6' }} />
            </CardMedia>

            <Typography variant="body1" align="left" className="tw-text-xs tw-text-blue-700">
              {comment.video_comment.title}
            </Typography>
          </div>
        </Link>
      );
    }

    return (
      <div style={{ display: 'flex' }}>
        <CardMediaContentOverlay onClick={() => setSelectedComment(comment)}>
          <div className="content-overlay" />
          <CardMedia className={classes.media} image={comment.video_comment.thumbnail} title="">
            {icon}
          </CardMedia>
        </CardMediaContentOverlay>

        <span style={{ marginLeft: 20, alignSelf: 'center' }}>
          <Typography variant="body1" align="left">
            {comment.comment}
          </Typography>
        </span>
      </div>
    );
  } else if (comment.comment) {
    return (
      <Typography
        variant="body1"
        align="left"
        style={{ whiteSpace: 'pre-line' }}
        className={classes.linkComment}
      >
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          {comment.comment}
        </Linkify>
      </Typography>
    );
  }
  return null;
}

interface EditCommentType {
  comment?: CommentType;
  setEdit: (value: boolean) => void;
}

function EditComment({ comment, setEdit }: EditCommentType) {
  const [state, setState] = useState({ ...comment });
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="subtitle1" align="left" display="inline">
          <b>
            <UsernameLink username={comment?.created_by?.username || ''} />
          </b>{' '}
          ● <span className={classes.createdAtText}>{comment?.created_at_humanize}</span>
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 20 }}>
        <TextField
          id="outlined-multiline-static"
          label="Add comment"
          multiline
          rows={5}
          defaultValue=""
          variant="outlined"
          fullWidth
          value={state.comment}
          onChange={ev => setState({ comment: ev.target.value })}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 10 }}>
        <Button
          variant="outlined"
          className={classnames(buttonPrimary, 'tw-mr-4 tw-text-sm')}
          onClick={() => {
            // @ts-ignore
            dispatch(commentApi.patchRequest({ comment: state.comment }, comment?.id || null)).then(
              () => {
                setEdit(false);
              },
            );
          }}
        >
          Save
        </Button>

        <Button
          variant="outlined"
          className={classnames(buttonDanger, 'tw-text-sm')}
          onClick={() => {
            setEdit(false);
          }}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
}

export function ConfirmText({
  content,
  onDelete,
}: {
  content: string;
  onDelete: VoidFunction;
}): JSX.Element {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button className="tw-mr-2 tw-text-xs tw-text-red-600" onClick={handleClickOpen}>
        Delete
      </Button>

      <ConfirmDeleteModal
        content={content}
        open={open}
        onClose={handleClose}
        handleDelete={() => {
          onDelete();
          handleClose();
        }}
      />
    </>
  );
}

interface CommentContentType {
  user: UserBaseType;
  comment: CommentType;
  setSelectedComment: (comment: CommentType) => void;
}

function CommentContent({ user, comment, setSelectedComment }: CommentContentType) {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const [] = useState({});
  const dispatch = useDispatch();

  if (comment.video_comment) {
  } else {
  }

  if (edit) {
    return <EditComment comment={comment} setEdit={setEdit} />;
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="subtitle1" align="left" display="inline">
          <b>
            <UsernameLink username={comment.created_by && comment.created_by.username} />
          </b>{' '}
          ● <span className={classes.createdAtText}>{comment.created_at_humanize}</span>
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <RenderComment comment={comment} setSelectedComment={setSelectedComment} />
      </Grid>

      {user && comment && comment.created_by && user.id === comment.created_by.id && (
        <>
          <Grid item xs={12} sm={12} md={4} lg={4} style={{ marginTop: 10 }}>
            {comment.comment && (
              <Button
                className="tw-mr-2 tw-text-xs tw-text-blue-700"
                onClick={() => {
                  setEdit(!edit);
                }}
              >
                Edit
              </Button>
            )}

            <ConfirmText
              content={`Do you want to delete ${comment.comment ? comment.comment : 'the video'}?`}
              onDelete={() => {
                dispatch(commentApi.deleteRequest(comment.id));
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}

interface CommentItem {
  user: UserBaseType;
  comment: CommentType;
  setSelectedComment: (comment: CommentType) => void;
}

export default function CommentItem({
  user,
  comment,
  setSelectedComment,
}: CommentItem): JSX.Element {
  return (
    <GridContainer key={comment.id}>
      <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
        <Grid item xs={2} sm={2} md={1} lg={1}>
          <CommentAvatar comment={comment} />
        </Grid>

        <Grid item xs={10} sm={10} md={11} lg={11}>
          <CommentContent comment={comment} setSelectedComment={setSelectedComment} user={user} />
        </Grid>
      </Grid>
    </GridContainer>
  );
}
